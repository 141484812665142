import React from "react";
import { Helmet } from "react-helmet";
import { Card, CardBody, Col, Row } from "reactstrap";

import { PageTitles } from "../../constants";
import { ImageGallery } from "../../components/ImageGallery";
import { LabeledDataItem } from "../../components/LabeledDataItem";
import { monthYear } from "../../utils/dateUtils";
import APP_CONFIG from "../../APP_CONFIG";
import { renderStars } from "../../utils/ratingUtils";
import { dateTimeFormat } from "../../utils/dateFormatUtils";
import { formatString } from "../../utils/commonUtils";

const HEADINGS = {
    NAME: "Name:",
    EMAIL: "Email:",
    PHONE: "Phone:",
    DOB: "DOB:",
    GENDER: "Gender:",
    ADDRESS: "Address:",
    LANGUAGE: "Language:",
    PAYMENT_METHODS: "Payment Methods:",
    TAGLINE: "Tagline:",
    ABOUT_ME: "About Me:",
    OVERALL_RATING: "Overall Rating",
    RELIABILITY: "Reliability",
    TIMELINESS: "Timeliness",
    COMMUNICATION: "Communication"
};
const format = dateTimeFormat.appDateFormat;

const DetailProfile = ({ data }) => {
    const overallRating = data?.rating?.avgRating || 0;
    const reliabilityRating = data?.rating?.avgReliabilityRating || 0;
    const timelinessRating = data?.rating?.avgTimelinessRating || 0;
    const communicationRating = data?.rating?.avgCommunicationRating || 0;

    return (
        <div className="mt-4">
            <Helmet>
                <title>{PageTitles.SEEKER_MANAGEMENT}</title>
            </Helmet>
            <Row>
                <Col xxl={12} md={12}>
                    <Row>
                        <Col xs={12}>
                            <Card className="overflow-hidden p-3 custom-card">
                                <CardBody className="p-0">
                                    <Row>
                                        <Col md={2}>
                                            <div className="d-flex align-items-start img-container p-2">
                                                <img
                                                    src={
                                                        data?.profilePicture?.thumbPath ||
                                                        APP_CONFIG.IMAGES.AVATAR_PLACEHOLDER
                                                    }
                                                    alt="Profile"
                                                    className="provider-img"
                                                    width={"100%"}
                                                    height={"100%"}
                                                />
                                            </div>
                                        </Col>

                                        <Col md={10}>
                                            <div className="d-flex align-items-center justify-content-between me-4">
                                                <div className="profile-header-text">
                                                    Personal Information
                                                </div>
                                                <div className="profile-header-status">
                                                    Cancellation Rate:{" "}
                                                    {data?.cancellationRate?.toFixed(2)}%
                                                </div>
                                            </div>
                                            <div style={{ color: "black" }} className="p-3">
                                                <Row>
                                                    <Col md={6}>
                                                        <LabeledDataItem
                                                            title={HEADINGS.NAME}
                                                            desc={data?.name || "N/A"}
                                                            titleColProps={{ xs: 5, md: 4, lg: 6 }}
                                                            descColProps={{ xs: 7, md: 8, lg: 6 }}
                                                        />
                                                    </Col>
                                                    <Col md={6}>
                                                        <LabeledDataItem
                                                            title={HEADINGS.EMAIL}
                                                            desc={data?.email || "N/A"}
                                                            titleColProps={{ xs: 5, md: 5, lg: 6 }}
                                                            descColProps={{ xs: 7, md: 7, lg: 6 }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div style={{ color: "black" }} className="p-3">
                                                <Row>
                                                    <Col md={6}>
                                                        <LabeledDataItem
                                                            title={HEADINGS.PHONE}
                                                            desc={
                                                                data?.countryCode && data?.phone ? (
                                                                    <div>
                                                                        {data?.countryCode}
                                                                        {data?.phone}
                                                                    </div>
                                                                ) : (
                                                                    "N/A"
                                                                )
                                                            }
                                                            titleColProps={{ xs: 5, md: 4, lg: 6 }}
                                                            descColProps={{ xs: 7, md: 8, lg: 6 }}
                                                        />
                                                    </Col>
                                                    <Col md={6}>
                                                        <LabeledDataItem
                                                            title={HEADINGS.DOB}
                                                            desc={
                                                                data?.dob
                                                                    ? monthYear(format, data?.dob)
                                                                    : "N/A"
                                                            }
                                                            titleColProps={{ xs: 5, md: 5, lg: 6 }}
                                                            descColProps={{ xs: 7, md: 7, lg: 6 }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div style={{ color: "black" }} className="p-3">
                                                <Row>
                                                    <Col md={6}>
                                                        <LabeledDataItem
                                                            title={HEADINGS.GENDER}
                                                            desc={
                                                                formatString(data?.gender) || "N/A"
                                                            }
                                                            titleColProps={{ xs: 5, md: 4, lg: 6 }}
                                                            descColProps={{ xs: 7, md: 8, lg: 6 }}
                                                        />
                                                    </Col>
                                                    <Col md={6}>
                                                        <LabeledDataItem
                                                            title={HEADINGS.ADDRESS}
                                                            desc={data?.location?.address || "N/A"}
                                                            titleColProps={{ xs: 5, md: 5, lg: 6 }}
                                                            descColProps={{ xs: 7, md: 7, lg: 6 }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div style={{ color: "black" }} className="p-3">
                                                <Row>
                                                    <Col md={6}>
                                                        <LabeledDataItem
                                                            title={HEADINGS.LANGUAGE}
                                                            desc={
                                                                data?.userLanguage
                                                                    ?.map(
                                                                        (language) => language?.name
                                                                    )
                                                                    .join(", ") || "N/A"
                                                            }
                                                            titleColProps={{ xs: 5, md: 4, lg: 6 }}
                                                            descColProps={{ xs: 7, md: 8, lg: 6 }}
                                                        />
                                                    </Col>
                                                    <Col md={6}>
                                                        <LabeledDataItem
                                                            title={HEADINGS.TAGLINE}
                                                            desc={data?.seeker?.tagLine || "N/A"}
                                                            titleColProps={{ xs: 5, md: 4, lg: 6 }}
                                                            descColProps={{ xs: 7, md: 8, lg: 6 }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div style={{ color: "black" }} className="p-3">
                                                <Row>
                                                    <Col md={6}>
                                                        <LabeledDataItem
                                                            title={HEADINGS.ABOUT_ME}
                                                            desc={data?.seeker?.aboutMe || "N/A"}
                                                            titleColProps={{ xs: 5, md: 5, lg: 6 }}
                                                            descColProps={{ xs: 7, md: 7, lg: 6 }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>

                                            <div className="d-flex align-items-center justify-content-between me-4">
                                                <div className="profile-header-text">
                                                    Ratings & Reviews
                                                </div>
                                            </div>
                                            <div style={{ color: "black" }} className="py-2 px-3">
                                                <Row>
                                                    <Col md={6}>
                                                        <LabeledDataItem
                                                            title={HEADINGS.OVERALL_RATING}
                                                            desc={
                                                                <div>
                                                                    {overallRating.toFixed(1)}{" "}
                                                                    {renderStars(overallRating)}{" "}
                                                                </div>
                                                            }
                                                            titleColProps={{ xs: 5, md: 4, lg: 6 }}
                                                            descColProps={{ xs: 7, md: 8, lg: 6 }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div style={{ color: "black" }} className="py-2  px-3">
                                                <Row>
                                                    <Col md={6}>
                                                        <LabeledDataItem
                                                            title={HEADINGS.RELIABILITY}
                                                            titleClass="fw-normal clr-theme-primary"
                                                            desc={
                                                                <div>
                                                                    {reliabilityRating.toFixed(1)}{" "}
                                                                    {renderStars(reliabilityRating)}{" "}
                                                                </div>
                                                            }
                                                            titleColProps={{ xs: 5, md: 4, lg: 6 }}
                                                            descColProps={{ xs: 7, md: 8, lg: 6 }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <LabeledDataItem
                                                            title={HEADINGS.TIMELINESS}
                                                            titleClass="fw-normal clr-theme-primary"
                                                            desc={
                                                                <div>
                                                                    {timelinessRating.toFixed(1)}{" "}
                                                                    {renderStars(timelinessRating)}{" "}
                                                                </div>
                                                            }
                                                            titleColProps={{ xs: 5, md: 4, lg: 6 }}
                                                            descColProps={{ xs: 7, md: 8, lg: 6 }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <LabeledDataItem
                                                            title={HEADINGS.COMMUNICATION}
                                                            titleClass="fw-normal clr-theme-primary"
                                                            desc={
                                                                <div>
                                                                    {communicationRating.toFixed(1)}{" "}
                                                                    {renderStars(
                                                                        communicationRating
                                                                    )}
                                                                </div>
                                                            }
                                                            titleColProps={{ xs: 5, md: 5, lg: 6 }}
                                                            descColProps={{ xs: 7, md: 7, lg: 6 }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>

                                            {data?.seeker?.userGallery.length > 0 && (
                                                <>
                                                    <div className="d-flex align-items-center justify-content-between me-4">
                                                        <div className="profile-header-text">
                                                            Gallery
                                                        </div>
                                                    </div>
                                                    <div style={{ color: "black" }} className="p-3">
                                                        <Row>
                                                            <Col md={12}>
                                                                <ImageGallery
                                                                    previousImages={
                                                                        data?.seeker?.userGallery
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default DetailProfile;
